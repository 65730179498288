import { userStore } from './user/user.store';
import {
  startOfDay,
  startOfTomorrow,
  startOfMinute,
  addHours,
  addMinutes,
  getMinutes,
  differenceInYears,
} from "date-fns";
import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";

export type ApiHeaders = { Authorization: string; Accept: string; 'Content-Type': string };

export const isRunTime = (): boolean => {
  return !!(typeof window !== 'undefined' && window);
};

export const getLocalStorageItem = (key, fallback?): any => {
  if (isRunTime()) {
    try {
      const storageObject = JSON.parse(localStorage.getItem(process.env.GATSBY_USTORE_LSTORAGE_KEY));
      return storageObject.hasOwnProperty(key) ? storageObject[key] : fallback;
    } catch (e) {
      return fallback;
    }
  }

  return fallback;
};

export const setLocalStorageItem = (key, value): any => {
  if (isRunTime()) {
    if (!localStorage.getItem(process.env.GATSBY_USTORE_LSTORAGE_KEY)) {
      localStorage.setItem(process.env.GATSBY_USTORE_LSTORAGE_KEY, '{"init": true}');
    }

    const storageObject = JSON.parse(localStorage.getItem(process.env.GATSBY_USTORE_LSTORAGE_KEY));
    storageObject[key] = value;

    localStorage.setItem(process.env.GATSBY_USTORE_LSTORAGE_KEY, JSON.stringify(storageObject));
  }

  return false;
};

export const removeLocalStorageItem = (key): any => {
  if (isRunTime()) {
    if (!localStorage.getItem(process.env.GATSBY_USTORE_LSTORAGE_KEY)) {
      localStorage.setItem(process.env.GATSBY_USTORE_LSTORAGE_KEY, '{"init": true}');
    }

    const storageObject = JSON.parse(localStorage.getItem(process.env.GATSBY_USTORE_LSTORAGE_KEY));
    delete storageObject[key];

    localStorage.setItem(process.env.GATSBY_USTORE_LSTORAGE_KEY, JSON.stringify(storageObject));
  }

  return false;
};

export const getAccessToken = (): string => {
  return getLocalStorageItem('uStoreAccessToken', '');
};

export const getUserOver18 = (): any => {
  return getLocalStorageItem('uStoreOver18', false);
};

export const promptPwaInstall = (): any => {
  return getLocalStorageItem('uStorePromptPwaInstall', true);
};

export const anonCartId = (): string => {
  return getLocalStorageItem('uStoreAnonCartId', null);
};

export const authRequestHeaders = (): ApiHeaders => {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
};

export const genericRequestHeaders = (): { Accept: string; 'Content-Type': string } => {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
};

export const fetchConfig = (
  primaryUrl: string,
  fallbackUrl: string
): { url: string; headers: ApiHeaders } => {
  const user = userStore.getValue();

  let url = primaryUrl;
  let headers = authRequestHeaders();

  if (!user.id) {
    url = fallbackUrl;
    delete headers.Authorization;
  }

  return {
    url,
    headers,
  };
};

export const getQueryVariable = (variable, location) => {
  const query = location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

export const interpolateTranslation = (rawValue: string, tokens: string[], replacements: string[]) => {
  for (let i = 0; i < tokens.length; i++) {
    rawValue = rawValue.replace(new RegExp(tokens[i], "g"), replacements[i]);
  }

  return rawValue;
};


export const australianStates = [
  {
    id:"ACT",
    value:"Australian Capital Territory",
  },
  {
    id:"NSW",
    value:"New South Wales",
  },
  {
    id:"NT",
    value:"Northern Territory",
  },
  {
    id:"QLD",
    value:"Queensland",
  },
  {
    id:"SA",
    value:"South Australia",
  },
  {
    id:"TAS",
    value:"Tasmania",
  },
  {
    id:"Victoria",
    value:"Victoria",
  },
  {
    id:"WA",
    value:"Western Australia",
  },
]

export const deliveryTimeOptions = () => {

  const now = new Date();
  const startOfToday = startOfDay(now);
  const openingTime = addHours(startOfToday, 11)
  const closingTime = startOfTomorrow();
  const shopIsStillClosed = openingTime > now;
  const minuteNow = getMinutes(now);
  let ASAPTime;

  if (shopIsStillClosed) {
    ASAPTime = addHours(openingTime, 1);
  } else {
    if (minuteNow > 30) {
      ASAPTime = addHours(now, 1);
      ASAPTime = addMinutes(ASAPTime, 60 - minuteNow)
    } else {
      ASAPTime = addHours(now, 1);
      ASAPTime = addMinutes(ASAPTime, 30 - minuteNow)
    }
  }

  // ASAPTime = startOfMinute(ASAPTime); wrong

  ASAPTime = addHours(now, 2);

  let option = addMinutes(ASAPTime, 30);
  const timeOptions = [];

  // temp remove other time options, keep only ASAP time for now
  // while (option <= closingTime) {
  //   timeOptions.push(option);
  //   option = addMinutes(option, 30)
  // }


  return {
    ASAPTime,
    timeOptions,
  }
}

export const productImageUrl = (img_path) => {
  if (process.env.GATSBY_USTORE_APP_CLIENT_URL === 'https://u-store.localenv.link') {
    return `https://s3.ap-southeast-2.amazonaws.com/files-of-kwickly.staging-sites.fishbulb.dev/${encodeURIComponent(img_path)}`
    // return `https://u-store.localenv.link/${encodeURIComponent(img_path)}`
  } else {
    return `${process.env.GATSBY_USTORE_APP_CLIENT_URL}/${encodeURIComponent(img_path)}`
  }
}

export const calculateAge = (date): number => {
  return differenceInYears(new Date(), date)
}


export const string_to_slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to   = "aaaaeeeeiiiioooouuuunc------";
  for (let i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const searchProducts = (search_value: string, categories: string[] = []) => {
  return ajax({
    url: `/api/store/1/products/search`,
    method: 'POST',
    headers: authRequestHeaders(),
    body: {
      search_value: search_value,
      categories: categories
    },
  }).pipe(
    map(({ response }) => response),
    catchError((error) => {
      return throwError(error);
    })
  );
}
