import { Store } from "@datorama/akita"

export interface UserAddress {
  id: number;
  customer_id: number;
  address_line_1: string;
  address_line_2: string;
  city: string;
  postal_code: string;
  state: string;
  country: string;
  is_primary: boolean;
}

export interface PaymentCard {
  id: number;
  customer_id: number;
  created_at: string;
  reference_uuid: string;
  type: string;
  card_holder: string;
  expiry_month: string;
  expiry_year: string;
  first_6_digits: string;
  last_4_digits: string;
  first_name: string;
  last_name: string;
  billing_address_1: string;
  billing_address_2: string;
  billing_city: string;
  billing_postcode: string;
  billing_state: string;
  billing_country: string;
  is_primary: boolean;
}

export interface UserState {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  birth_date: string;
  auth_provider: string;
  addresses: UserAddress[];
  default_address: UserAddress;
  payment_cards: PaymentCard[];
}

export class UserStore extends Store<UserState> {
  constructor() {
    super({}, { name: "user", resettable: true })
  }
}

export const userStore = new UserStore()
